body {
    background-color: darkgrey;
}

.container {
    max-width: 960px;
}
.dataTables_filter {
    width: 50%;
    float: right;
    text-align: right;
}
#main {
    
    height: 100vh;
    overflow: auto;
    padding-left: 0;
    padding-right: 0;
}

#side-menu-bar {
    width: 220px;
    float: left;
    height: 100vh;
    background-color: #006857;
    color: white;
}

    #side-menu-bar li {
        background-color: #025043;
        border-bottom: solid 2px #006857;
    }

    #side-menu-bar a {
        color: inherit;
    }

        #side-menu-bar a:hover {
            background-color: #ae133c;
        }

.login-info {
    text-align: left;
    vertical-align: middle;
    height: 56.6px;
    line-height: 3em;
    margin-left: 0.25rem;
}

    .login-info a span {
        text-transform: uppercase;
    }

    .login-info img {
        width: 25px;
        height: auto;
        display: inline-block;
        vertical-align: middle;
        margin-top: 1px;
        margin-right: 5px;
        margin-left: 0;
        border-left: 3px solid #fff;
    }

#main-content-container {

    /*margin-left: 220px;*/
    max-height:100%;
    background-color: #f9f9f9;
}

#main-content {
    overflow-y: auto;
    max-height: 100%;
    background-color: #ffffff;
}
 
.SimpleTopInformationSection {
    background-color: #faf4b4; /*Light Gray*/
    color: #696969;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    border: 2px;
    border-color: #383838;
    display: inline-block;
    border-radius: 25px; 
}
.funkyBackground {
    background: linear-gradient(to bottom right, #830024 50%, transparent 50.5%) no-repeat top,
    /* bottom part */
    linear-gradient(0deg, #f9f9f9, #f9f9f9) no-repeat bottom;
    /* top portion */
    color: white;
    padding-bottom: 3em;
    background-size: 100% 7em, 100% calc(100% - 7em);
    /*margin-left: 220px;*/
    max-height: 100%;
}

.padding-half-rem {
    padding: 0.5rem !important;
}

.dashboard-panel-text {
    color: #006857 !important;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #3a7b7f;
    border-color: #2c5d60;
}

    .btn-primary:hover {
        background-color: #138496;
        border-color: #117a8b;
    }

/*.btn-info {
    background-color: #2b3238;
}*/

.fa-icons {
    padding-left: 0;
    padding-bottom: 1px;
    margin-bottom: 20px;
    list-style: none;
    overflow: hidden;
}

    .fa-icons li {
        float: left;
        width: 33.3%;
        height: 215px;
        padding: 10px;
        margin: 0 -1px -1px 0;
        font-size: 16px;
        line-height: 1.4;
        text-align: center;
        border: 1px solid #ddd;
    }

    .fa-icons .fa {
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 75px;
    }

.svg-inline--fa {
    margin-top: 1.5em;
    width: 2.5em !important;
    height: 3em !important;
    color: #006857;
} 
@media (min-width: 768px) {
    .svg-inline--fa {
        margin-top: 0;
        width: 5em !important;
        height: 7em !important;
        color: #002800; /*006857;*/
    }
}

.ShipsRadioColumnItem{
    width: 97%;
    min-height: 200px;
    padding: 0px;
    margin: 0px;
    font-size: 16px;
    line-height: 1.4;
    text-align: center;
    list-style: none;
    border-radius: 25px;
    color: darkgrey;
    text-align: center;
}

.ShipsRadioTwoColumnItems li {
    float: left;
    width: 47%; 
    height: 280px;
    padding: 10px;
    margin: 5px 10px 5px 10px;
    font-size: 16px;
    line-height: 1.4;
    text-align: center;
   /* border: 1px solid #ddd;*/
    list-style: none;
    border-radius: 25px;
   /* background-color: #DCDCDC; Very light gray*/
    color: darkgrey;
    text-align: center;
}

.ShipsRadioThreeColumnItems li {
    float: left;
    width: 30%;
    height: 280px;
    padding: 10px;
    margin: 5px 10px 5px 10px;
    font-size: 16px;
    line-height: 1.4;
    text-align: center;
    /* border: 1px solid #ddd;*/
    list-style: none;
    border-radius: 25px;
    /*background-color: #DCDCDC; Very light gray*/
    color: darkgrey;
    text-align: center;
}

nav ul {
    width: 100%;
    padding: 40px 28px 25px 0;
    padding: 0;
    margin: 0;
    font-size: 13px;
    line-height: .5em;
    list-style: none;
    position: relative;
}

    nav ul li a {
        line-height: normal;
        font-size: 16px;
        padding: 10px 10px 10px 11px;
        /*color: #c0bbb7;*/
        display: block;
        font-weight: 400;
        text-decoration: none !important;
        position: relative;
    }


.Sub-Section-Rounded {
    border-radius: 25px;
    background-color: #DCDCDC; /*Very light gray*/
    color: darkgrey;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
}


.DropdownTopBar{
    display: block;
    height: calc(1.5em + 0.75rem + 1px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-sizing: border-box;
}

.Sub-Heading-Title {
    color: #696969; /*very dark gray*/
    padding: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
    text-align: left;
    font-size: 24px;
    font-weight: 600;
}

.Sub-Heading-Title-Large {
    color: #696969; /*very dark gray*/
    background-color:transparent;
    padding: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
    text-align: left;
    font-size: 22px;
    font-weight:bold;
}

.btn-licence-details {
    background-color: #679976;
    color: white;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    border: 2px;
    border-color: #383838;
    display: inline-block;
}

.btn-licence-details:hover {
    background-color: #598566;
    color: white;
}

.btn-amendedlicence-details {
    background-color: #8f3127; /* f7ca81; */
    color: #ffffff;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    border: 2px;
    border-color: #383838;
    display: inline-block;
}

    .btn-amendedlicence-details:hover {
        background-color: #70261d;
        color: #ffffff;
    }

.btn-licencetransfer-details {
    background-color: #465e6e; /* c7ddeb; */
    color: #ffffff;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    border: 2px;
    border-color: #383838;
    display: inline-block;
}

    .btn-licencetransfer-details:hover {
        background-color: #32434f; /* c7ddeb; */
        color: #ffffff;
    }


.bg-customWarning {
    border-top: 6px solid #f7ca81;
}


.bg-customError {
    border-top: 6px solid #f0a495;
}



.bg-customGood {
    border-top: 6px solid #c3e8c5;
}

.bg-customNeutral {
    border-top: 6px solid #c7ddeb;
}


.bg-customWarningFull {
    border-top: 6px solid #f7ca81;
    background-color: #DCDCDC;
    width: 160px;
    padding: 10px;
    margin: 10px;
    text-align: center;
    border-radius: 0.3rem;
}



.bg-customBland { 
    padding: 10px;
    margin: 10px;
    text-align: center; 
}

.bg-customErrorFull {
    border-top: 6px solid #f0a495;
    background-color: #DCDCDC;
    width: 160px;
    padding: 10px;
    margin: 10px;
    text-align: center;
    border-radius: 0.3rem;
}
.bg-customGoodFull {
    border-top: 6px solid #c3e8c5;
    background-color: #DCDCDC;
    width: 160px;
    padding: 10px;
    margin: 10px;
    text-align: center;
    border-radius: 0.3rem;
}

.rowCustom {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
}
 
.blockCustom {
    width: 100px;
}

.centeredCustom {
    justify-content: center;
}

.btn-AddEmergencyContact {
    background-color: #DCDCDC; /*Light Gray*/
    color: #696969;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    border: 2px;
    border-color: #383838;
    display: inline-block;
}

    .btn-AddEmergencyContact:hover {
        background-color: #B8B8B8;
    }

    .HarpImage {
        background-image: url("./Images/Harp.png");
        height: 50px;
        width: 40px;
    }

.WorldImage {
    background-image: url("./Images/world.png");
    background-size: 100% 100%;
}

.btn-app-details {
    background-color: #666257; /*#e8e0cc; */
    color: #ffffff;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;
    border: 2px;
    border-color: #383838;
}

    .btn-app-details:hover {
        background-color: #4d4941; /* #ccc5b1; */
        color: #ffffff;
    }


.btn-licence-actions {
    border-radius: 25px;
    font-weight:bold;
    font-size:18px;
    text-align: left;
    background-color: #DCDCDC; /*Light Gray*/
    color: #696969;
    padding: 35px;
    margin-right: 20px;
    margin-bottom: 10px;
    text-align: center;
    border: 2px;
    border-color: #383838;
    min-width: 100%;
}

    .btn-licence-actions:hover {
        background-color: #B8B8B8;
    }

.ShipsRadioHeader {
    background-color: #004D44;
    min-height: 76px;
}


.ShipsRadioFooter {
    background-color: #176e5c !important;
    min-height: 80px;
}


.Shadowing {
    -webkit-box-shadow: 6px 6px 4px 0px #ccc; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: 6px 6px 4px 0px #ccc; /* Firefox 3.5 - 3.6 */
    box-shadow: 6px 6px 4px 0px #ccc; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
}

    /* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
    .active, .accordion:hover {
        background-color: #ccc;
    }

/* Style the accordion panel. Note: hidden by default */
.panel {
    padding: 0 18px;
    background-color: white;
    display: none;
    overflow: hidden;
} 

.jumbotron-inline-style1 {
    padding: 10px 10px 5px 10px;
    margin-bottom: 10px;
}

.div-inline-hide {
    display: none;
}


.autoaddress-text-box {
    display: block !important;
    width: 80% !important;
    height: calc(1.5em + .75rem + 2px) !important;
    padding: .375rem .75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: .25rem !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.autoaddress-button {
    color: #fff !important;
    background-color: #3a7b7f !important;
    border-color: #2c5d60 !important;
    border-radius: .25rem !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.listItem-Button {
    line-height: inherit !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    color: #003d7a !important;
    font-weight: 600;
}

.smallWhiteIcon {
    font-size: 2px;
    color: #ffffff;
}

.smallIcon {
    font-size: 3px;
}

.b-active {
    color: #6c757d;
}

.form-label {
    /*color: #666666;*/
    font-weight: bold
}

    .srl-hr {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        border: 2px solid #004D44;
        opacity: .8;
    }

    .srl-ul {
        list-style-type: '- ';
    }

    .srl-ul > li {
        /*font-size: 22px;*/
        font-weight: bold;
    }

    .srl-ul > li > a {
        /*font-size: 22px;*/
        font-weight: bold;
        color: #212529;
    }

.footer-main-text-div {
    color: white;
    font-size: 20px;
    text-align: center;
    /*text-align: left;*/
    word-break: break-all;
    text-decoration: none;
    padding: 1px;
    margin-left: 5px;
    margin-right: 130px;


}

.footer-main-text-div > a {
    color: white;
}

.footer-label {
    font-weight: normal;
    color: white;
    white-space: normal;
}


#footer-container {
    padding:0;
    width: 90%;
}
